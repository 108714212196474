import React from "react"
import { graphql } from 'gatsby'
import EventLayout from "../../../components/layouts/eventLayout"
import Button from "../../../components/button"

const breakpoints = [980]
const mq = breakpoints.map(
    bp => `@media (min-width: ${bp}px)`
)

const TCell = ({ width, mWidth, styles, children }) => (
  <div css={{
    textAlign: `center`,
    boxSizing: `border-box`,
    flexGrow: `1`,
    width: mWidth,
    padding: `0.8em 1.2em`,
    overflow: `hidden`,
    listStyle: `none`,
    border: `solid 3px white`,
    background: `rgba(112, 128, 144, 0.2)`,
    [mq[0]]: {
      width: width,
      marginBottom: `0`,
    },
    ...styles
  }}>
    {children}
  </div>
)


const CurisinInTheCountryRegistrationPage = ({data, ...props}) => {

  const riders = data.allRidersCsv.nodes.map(i =>
    <>
      <TCell width="33%" mWidth="100%">
        <h3>{i.lastName}, {i.firstName} </h3>
        <p>{i.city}, {i.state}</p>
      </TCell>
    </>
  )

  
  return(
  <EventLayout
    pageTitle="Cruisin’ in the Country"
    tabs={[
      { to: "/programs/cruisin-in-the-country", text: "Overview" },
      {
        to: "/programs/cruisin-in-the-country/routes",
        text: "Routes",
      },
      {
        to: "/programs/cruisin-in-the-country/registration",
        text: "Registration",
      },
      {
        to: "/programs/cruisin-in-the-country/accomodations",
        text: "Accommodations",
      },
     // {
     //   to: "/programs/cruisin-in-the-country/run",
     //   text: "Run",
     // },
    ]}
  >
    <h2 css={{marginBottom: `2rem`}}>Event Registration</h2>
    <p>
      We are pleased to offer 3 registration schedules for Cruisin’. Early Bird registration is $45 and will be offered
      June 1st until August 31st. The registration fee for September 1st through October 31st is $55. Registration
      from November 1st through November 9th (including walk-up registrations) is $65. Active and retired
      military can register for $45, at any time. (Please note – event t-shirts CANNOT be guaranteed for
      registrations received after October 15th.) You can download a registration form
      (www.claxtonevanschamber.com) and mail in with payment. To register by credit card, log on to www.
      Active.com. On-site registration will be offered at Cruisin’ Headquarters Friday, November 8th, 2:00- 7:00
      p.m. and Saturday, November 9th, beginning at 7:00 a.m.
    </p><br />
    <p>
All routes begin and end at Cruisin’ Headquarters, the Evans County Recreation Department. Physical
Address – 11093 Highway 280 East, Claxton, Georgia 30417.
    </p>
    <h3 css={{fontSize: `1.25rem`, margin: `2rem 0 0.5rem 0`}}>Post Ride Meal</h3>
    <p>
A complimentary post ride meal is included with your registration fee. The post ride meal will be
offered on Saturday, November 9th, from 11:00 am until 6:00 pm at Cruisin’ Headquarters. The menu
will be posted soon. Regular, Vegan, Vegetarian, and Gluten Free options available. Please indicate
special dietary needs on your registration form.
    </p>
    <div css={{margin: `2rem 0`}}>
    <a css={{
        display: "inline-block",
        marginTop: "0.66rrem",
        backgroundColor: "rgb(9, 37, 86)",
        color: "#fff",
        textDecoration: "none",
        padding: "0.667rem",
        borderRadius: "2px",
        marginRight: `1.5rem`,
      }} href="/docs/2024CruisinRegistration.pdf">Download PDF Registration</a>
      <Button to="https://www.active.com/claxton-ga/cycling/races/28th-annual-cruisin-in-the-country-2024" text="Register Online" />
    </div>
{/*--
    <h3 css={{fontSize: `1.25rem`, margin: `2rem 0 0.5rem 0`}}>Registered Riders</h3>
    <div css={{
        display: `flex`,
        flexWrap: `wrap`,
        margin: `0 0 3rem 0`,
        padding: `0`,
      }}>
      {riders}
    </div>
    --*/}
  </EventLayout>
)
}


export const query = graphql`
query RidersQuery {
  allRidersCsv {
    nodes {
     	firstName
    	lastName
    	city
    	state
    }
  }
}

`



export default CurisinInTheCountryRegistrationPage
